.fill-circle-container {
  position: relative;
}

.fill-circle {
  transform: rotate(-90deg);
  height: 100%;
}

.fill-circle circle {
  fill: none;
  stroke-width: 10%;
}

.fill-circle .background {
  stroke: var(--dim-color);
}

.fill-circle .mechanical {
  stroke: var(--velib-mechanical-color);
}

.fill-circle .ebike {
  stroke: var(--velib-ebike-color);
}

.total-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.total-container .total {
  font-size: 3em;
  line-height: 1em;
}

.total-container .label {
  font-size: small;
}
