.stations-list {
  list-style: none;
  padding-left: 0;
}

.stations-list li {
  margin: 0;
  padding: 0;
}

.stations-list a {
  display: inherit;
  color: inherit;
  text-decoration: inherit;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 4px;
  white-space: nowrap;
  position: relative;
  background: var(--background-color);
}

.stations-list a:hover {
  --dim-color: white;
  --background-color: #e2eff0;
}

.stations-list a > * {
  display: inline-block;
  vertical-align: middle;
}

.stations-list .detail {
  margin-left: 8px;
  font-size: 14px;
}

.stations-list .detail .svg-inline--fa {
  margin-left: 1px;
  font-size: 10px;
}

.stations-list .detail h4 {
  margin: 0;
  display: inline;
}

.stations-list .detail p {
  margin: 0;
  display: inline;
  vertical-align: middle;
}

.stations-list .graph-container {
  padding-left: 8px;
  position: absolute;
  right: 8px;
  width: 65px;
  height: 40px;
}

.stations-list .graph svg {
  cursor: pointer;
}
