.station-view {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 40px;
}

.station-view h2 {
  font-size: 2em;
  margin-top: 0;
}

.station-view .recap {
  margin-bottom: 30px;
  align-items: center;
  max-width: 400px;
  text-align: center;
}

.station-view .recap .detail {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  list-style: none;
  font-size: 1.5em;
  margin: 0 0px;
  padding-left: 20px;
}

.station-view .recap .detail strong {
  display: inline-block;
  width: 70px;
  text-align: right;
  margin-right: 8px;
}

.station-view .recap .detail strong .svg-inline--fa {
  width: 25px;
  font-size: 20px;
}

.station-view .fill-circle-container {
  display: inline-block;
  vertical-align: middle;
}

.station-view .last-update {
  font-size: small;
  color: gray;
}

.station-view .main-column {
  flex: 3 3 600px;
}

.station-view .side-column {
  flex: 1 1 350px;
  max-width: 100%;
}

.side-column h3 {
  margin-bottom: 4px;
}

.stations-list {
  list-style: none;
  padding-left: 16px;
}

.side-column .map {
  width: 100%;
  height: 300px;
  border-radius: 10px;
}
