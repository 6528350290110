body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --velib-mechanical-color: #78b648;
  --velib-ebike-color: #61c3dc;
  --velib-mechanical-color-dark: #476f29;
  --velib-ebike-color-dark: #3d7e8e;
  --velib-station-dark: #5e5e5e;
  --dim-color: #e2eff0;
  --background-color: white;
}

.mechanical {
  color: var(--velib-mechanical-color-dark);
}

.ebike {
  color: var(--velib-ebike-color-dark);
}

.station {
  color: var(--velib-station-dark);
}

#root {
  padding: 20px;
}

@media screen and (min-width: 1400px) {
  #root {
    padding-top: 80px;
  }
}
